export const seo = {
  title: {
    pl: 'Flota CARGONITE | Firma Transportowa Wrocław',
    en: 'Fleet',
  },
  description: {
    pl: 'Dbając o zabezpieczenie transportu naszych klientów, korzystamy dodatkowo z kompletu pasów i łańcuchów zabezpieczających, podkładek zabezpieczających delikatny towar, a także belek uniemożliwiające przemieszczanie się ładunków niestabilnych.',
    en: `In order to secure our clients’ goods, we additionally use a set of tie-down straps and chains, pads for securing fragile goods, as well as beams to prevent the movement of unstable loads.`,
  },
}
