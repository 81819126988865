import React from 'react'
import { colors, s } from '../../../style'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  gTitle,
  gSubtitle,
  gDesc,
  globals,
} from '../../../style/config/globals'

const Vehicle = ({ title, subtitle, desc, image }) => {
  return (
    <div css={sInner}>
      <div css={sText}>
        <span css={gSubtitle}>{subtitle}</span>
        <h4 css={gTitle}>{title}</h4>
        {desc.map((text, id) => (
          <p css={[gDesc, { paddingBottom: 25 }]} key={id}>
            {text}
          </p>
        ))}
      </div>
      <div css={sImage}>
        <GatsbyImage
          alt={title}
          image={image}
          css={globals.absolute}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
    </div>
  )
}

const sInner = {
  display: 'flex',

  backgroundColor: colors.container,
  [s.md_down]: {
    padding: 50,
    marginBottom: 50,
  },
  [s.sm_down]: {
    flexDirection: 'column',
  },
  [s.xs]: {
    padding: 37,
    marginBottom: 37,
  },
  [s.lg]: {
    padding: 100,
    marginBottom: 100,
  },
}
const sText = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '60%',
  flexBasis: '60%',
  padding: '0 100px 0 0',
  [s.sm_down]: {
    maxWidth: '100%',
    flexBasis: '100%',
    padding: '0 0 50px 0',
  },
}
const sImage = {
  flexGrow: 1,
  maxWidth: '40%',
  flexBasis: '40%',
  aspectRatio: '3/2',
  position: 'relative',
  [s.sm_down]: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
}

export default Vehicle
