import React from 'react'
import { navigate } from '@reach/router'
import { colors, s } from '../../../style'
import { gTitle, gSubtitle, gDesc } from '../../../style/config/globals'
import ButtonOutline from '../../../components/ButtonOutline'
import { useDataContext } from '../../../context/data.context'

const Trailer = ({ title, subtitle, desc, icon, button, bullets }) => {
  const { setFleet } = useDataContext()
  return (
    <div css={sInner}>
      <div css={sTop}>
        <div css={sText}>
          <span css={gSubtitle}>{subtitle}</span>
          <h4 css={gTitle}>{title}</h4>
          <p css={gDesc}>{desc}</p>
        </div>
        <div css={sBullets}>
          {bullets.map((bullet, id) => (
            <div key={id} css={sBullet}>
              <span />
              {bullet}
            </div>
          ))}
        </div>
      </div>
      <div css={sBottom}>
        {icon ? <img src={icon} css={sImage} alt={title} /> : <div />}
        <ButtonOutline
          mix
          action={() => {
            navigate('/fleet#form')
            setFleet(title)
          }}>
          {button.text}
        </ButtonOutline>
      </div>
    </div>
  )
}

const sInner = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.container,
  [s.md_down]: {
    padding: 50,
    marginBottom: 50,
  },
  [s.xs]: {
    padding: 37,
    marginBottom: 37,
  },
  [s.lg]: {
    padding: 100,
    marginBottom: 100,
  },
}

const sTop = {
  display: 'flex',
  [s.sm_down]: {
    flexDirection: 'column',
  },
}

const sBottom = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 50,
  [s.sm_down]: {
    flexDirection: 'column',
    marginTop: 50,
  },
}

const sText = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '75%',
  flexBasis: '75%',
  padding: '0 100px 0 0',
  [s.sm_down]: {
    maxWidth: '100%',
    flexBasis: '100%',
    padding: '0 0 50px 0',
  },
}

const sBullets = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '25%',
  flexBasis: '25%',
  [s.sm_down]: {
    maxWidth: '100%',
    flexBasis: '100%',
  },
}

const sBullet = {
  display: 'flex',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  lineHeight: '1.2em',
  marginBottom: 25,
  span: {
    flexShrink: 0,
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: colors.main,
    boxShadow: `0px 0px 12px ${colors.main}`,
    marginRight: 25,
  },
}

const sImage = {
  height: 60,
  maxWidth: '100%',
  [s.sm_down]: {
    marginBottom: 37,
  },
}

export default Trailer
