import React from 'react'
import Layout from '../components/Layout'
import Fleet from '../page/Fleet/Fleet'

const FleetPage = () => (
  <Layout>
    <Fleet />
  </Layout>
)

export default FleetPage
