import React from 'react'
import Headline from '../../../components/Headline'
import { useLangContext } from '../../../context/lang.context'
import { gContainer } from '../../../style/config/globals'
import { containersSectionContent as content, containers } from '../content'
import Container from './Container'

const ContainersSection = () => {
  const { lang } = useLangContext()

  return (
    <section>
      <Headline
        title={content.title[lang]}
        back={content.back[lang]}
        desc={content.desc[lang]}
      />
      <div css={gContainer}>
        {containers.map((container, id) => (
          <Container key={id} data={container} />
        ))}
      </div>
    </section>
  )
}

export default ContainersSection
