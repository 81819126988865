import React from 'react'
import Seo from '../../components/Seo'
import { seo } from './seo'
import { useLangContext } from '../../context/lang.context'
import IntroSection from './IntroSection/IntroSection'
import VehiclesSection from './VehiclesSection/VehiclesSection'
import TrailerSection from './TrailersSection/TrailerSection'
import FactsSection from './FactsSection/FactsSection'
import FormService from '../Services/FormService/FormService'
import ContainersSection from './ContainersSection/ContainersSection'

const Fleet = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />

      <TrailerSection />
      <VehiclesSection />
      <ContainersSection />
      <FactsSection />
      <FormService page='fleet' pages={['fleet']} />
    </>
  )
}

export default Fleet
