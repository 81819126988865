import React from 'react'
import { useLangContext } from '../../../context/lang.context'
import { alpha, colors, s } from '../../../style'
import { gTitle, gSubtitle, gDesc } from '../../../style/config/globals'

const Container = ({ data }) => {
  const { lang } = useLangContext()
  const { title, subtitle, desc, paragraphs } = data
  return (
    <div css={sInner}>
      <div css={sText}>
        <span css={gSubtitle}>{subtitle[lang]}</span>
        <h4 css={gTitle}>{title[lang]}</h4>
        <p css={[gDesc, { paddingBottom: 25 }]}>{desc[lang]}</p>
        <div css={sParagraphs}>
          {paragraphs?.map((para, id) => (
            <div key={id} css={sParagraph}>
              <h5>{para.title[lang]}</h5>
              <div>
                {para.specs.map((spec, id) => (
                  <p key={id}>
                    <strong>{spec.name[lang]} : </strong>
                    {spec.value}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const sParagraphs = {
  display: 'flex',

  justifyContent: 'space-between',
  [s.md_down]: {
    flexDirection: 'column',
  },
}

const sParagraph = {
  margin: '1rem 0 2rem',
  [s.lg]: { paddingRight: '2rem', width: '50%' },

  h5: { fontSize: '1.25rem' },
  strong: {
    color: colors.white.concat(alpha[30]),
    marginRight: '0.5rem',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontFamily: 'Staatliches',
  },
  p: {
    marginTop: '1rem',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    fontFamily: 'Red Hat Display',
    textTransform: 'uppercase',
    [s.xs]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}

const sInner = {
  display: 'flex',

  backgroundColor: colors.container,
  [s.md_down]: {
    padding: 50,
    marginBottom: 50,
  },
  [s.sm_down]: {
    flexDirection: 'column',
  },
  [s.xs]: {
    padding: 37,
    marginBottom: 37,
  },
  [s.lg]: {
    padding: 100,
    marginBottom: 100,
  },
}
const sText = {
  display: 'flex',
  flexDirection: 'column',
}

export default Container
