import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Headline from '../../../components/Headline'
import { getImage } from 'gatsby-plugin-image'
import { useLangContext } from '../../../context/lang.context'
import { gContainer } from '../../../style/config/globals'
import { vehiclesSectionContent as content, vehicles } from '../content'
import Vehicle from './Vehicle'

const VehiclesSection = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    query {
      vehicle1: file(
        absolutePath: { regex: "/images/fleet/vehicles/vehicle1.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      vehicle2: file(
        absolutePath: { regex: "/images/fleet/vehicles/vehicle2.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      vehicle3: file(
        absolutePath: { regex: "/images/fleet/vehicles/vehicle3.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      vehicle4: file(
        absolutePath: { regex: "/images/fleet/vehicles/vehicle4.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            transformOptions: { fit: CONTAIN }
          )
        }
      }
      vehicle5: file(
        absolutePath: { regex: "/images/fleet/vehicles/vehicle5.png/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            layout: FULL_WIDTH
            quality: 100
            placeholder: TRACED_SVG
            formats: [PNG]
            transformOptions: { fit: CONTAIN }
          )
        }
      }
    }
  `)

  return (
    <section>
      <Headline
        title={content.title[lang]}
        back={content.back[lang]}
        desc={content.desc[lang]}
      />
      <div css={gContainer}>
        {vehicles.map(({ title, subtitle, desc, image }, id) => (
          <Vehicle
            key={id}
            title={title[lang]}
            subtitle={subtitle[lang]}
            desc={desc[lang]}
            image={getImage(query[image])}
          />
        ))}
      </div>
    </section>
  )
}

export default VehiclesSection
