import React, { useState, useEffect, useCallback } from 'react'
import Headline from '../../../components/Headline'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import { gContainer } from '../../../style/config/globals'
import { factsSectionContent as content, facts } from '../content'
import { colors, s, alpha, globals } from '../../../style'
import Triangle from '../../../assets/Triangle'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const FactsSection = () => {
  const [selected, setSelected] = useState(0)
  const { lang } = useLangContext()
  const factsNum = facts.length

  const decreaseSelected = useCallback(
    () =>
      selected - 1 >= 0 ? setSelected(selected - 1) : setSelected(factsNum - 1),
    [selected, setSelected, factsNum]
  )

  const increaseSelected = useCallback(
    () =>
      selected + 1 <= factsNum - 1 ? setSelected(selected + 1) : setSelected(0),
    [selected, setSelected, factsNum]
  )

  const query = useStaticQuery(graphql`
    query {
      fact1: file(absolutePath: { regex: "/images/fleet/facts/fact1.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      fact2: file(absolutePath: { regex: "/images/fleet/facts/fact2.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      fact3: file(absolutePath: { regex: "/images/fleet/facts/fact3.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      fact4: file(absolutePath: { regex: "/images/fleet/facts/fact4.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 80
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  useEffect(() => {
    const timer = setInterval(() => {
      increaseSelected()
    }, 12000)
    return () => {
      clearInterval(timer)
    }
  }, [increaseSelected])

  return (
    <section css={sSection}>
      <Headline
        title={content.title[lang]}
        back={content.back[lang]}
        desc={content.desc[lang]}
      />
      <div css={sOuter}>
        <div css={[gContainer, sContainer]}>
          {facts.map((fact, id) => (
            <div css={[sFact, selected !== id && { opacity: 0 }]} key={id}>
              <GatsbyImage
                image={getImage(query[`fact${id + 1}`])}
                alt={fact.title[lang]}
                css={globals.absolute}
              />
              <div css={sGradient} />
              <div css={{ position: 'relative', inset: 'auto' }}>
                <h6>{fact.title[lang]}</h6>
                <p>{fact.desc[lang]}</p>
              </div>
            </div>
          ))}
          <div
            onClick={decreaseSelected}
            onKeyPress={decreaseSelected}
            tabIndex={0}
            role='button'
            aria-label='Previous'>
            <Triangle extraCss={[sArrow, sArrowLeft]} />
          </div>
          <div
            onClick={increaseSelected}
            onKeyPress={increaseSelected}
            tabIndex={0}
            role='button'
            aria-label='Next'>
            <Triangle extraCss={[sArrow, sArrowRight]} />
          </div>
        </div>
      </div>
      <div css={sDots}>
        {facts.map((fact, id) => (
          <div
            css={[sDot, id !== selected && { opacity: 0.25 }]}
            key={id}
            onClick={() => setSelected(id)}
            onKeyPress={() => setSelected(id)}
            tabIndex={0}
            role='button'
            aria-label='Show'
          />
        ))}
      </div>
    </section>
  )
}

const sOuter = {
  padding: '0 50px 50px',
  [s.xs]: {
    padding: '0 25px 25px',
  },
}

const sSection = {
  marginBottom: 100,
}

const sContainer = {
  position: 'relative',
  minHeight: 400,
  height: 'max-content',
  maxWidth: 1280 - 100,
  // [s.xs]: {
  //   minHeight: 480,
  // },
}

const sFact = {
  transition: 'opacity 0.3s ease-out',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  padding: 100,
  backgroundColor: colors.container,
  [s.md_down]: {
    padding: 50,
  },
  [s.xs]: {
    padding: 37,
  },

  h6: {
    fontSize: '1rem',
    letterSpacing: '0.25em',
    lineHeight: '1.618em',
    marginBottom: 50,
    [s.xs]: {
      marginBottom: 25,
    },
  },
  p: {
    fontSize: '0.875rem',
    opacity: 1,
    lineHeight: '2em',
    [s.xs]: {
      fontSize: '0.75rem',
    },
  },
}

const sDots = {
  ...gContainer,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const sDot = {
  transition: 'opacity 0.2s ease-out, transform 0.2s ease-out',
  cursor: 'pointer',
  width: 20,
  height: 20,
  margin: '0 12px 25px',
  backgroundColor: colors.white,
  '&:hover': {
    transform: 'scale(1.5)',
  },
  [s.sm_down]: {
    width: 12,
    height: 12,
    margin: '0 6px 12px',
  },
}

const sArrow = {
  cursor: 'pointer',
  transition: 'opacity 0.2s ease-out',
  position: 'absolute',
  top: '50%',
  opacity: 0.25,
  '&:hover': {
    [s.hover]: { opacity: 1 },
  },
}

const sArrowLeft = {
  left: -50,
  transform: 'translateY(-50%) rotate(-45deg)',
  [s.md_down]: {
    left: -25,
  },
  [s.xs]: {
    transform: 'translateY(-50%) rotate(-45deg) scale(0.5)',
  },
}

const sArrowRight = {
  right: -50,
  transform: `translateY(-50%) rotate(-${45 + 180}deg)`,
  [s.md_down]: {
    right: -25,
  },
  [s.xs]: {
    transform: `translateY(-50%) rotate(-${45 + 180}deg) scale(0.5)`,
  },
}

const sGradient = {
  ...globals.absolute,
  background: `linear-gradient(to bottom, 
    ${colors.container},
    ${colors.container.concat(alpha[90])} 40%,
    ${colors.container.concat(alpha[60])} 80%,
    ${colors.container.concat(alpha[0])} 100%
    )`,
  [s.sm_down]: {
    background: `linear-gradient(to bottom, 
        ${colors.container},
        ${colors.container.concat(alpha[90])} 40%,
        ${colors.container.concat(alpha[80])} 70%,
        ${colors.container.concat(alpha[60])} 85%,
        ${colors.container.concat(alpha[30])} 100%
        )`,
  },
}

export default FactsSection
