import React from 'react'
import Headline from '../../../components/Headline'
import { useLangContext } from '../../../context/lang.context'
import { gContainer } from '../../../style/config/globals'
import { trailersSectionContent as content, trailers } from '../content'
import Trailer from './Trailer'

import trailer1 from '../../../assets/fleetTrailers/trailer1.svg'
import trailer2 from '../../../assets/fleetTrailers/trailer2.svg'
import trailer3 from '../../../assets/fleetTrailers/trailer3.svg'
import trailer4 from '../../../assets/fleetTrailers/trailer4.svg'

const trailerIcons = {
  trailer1,
  trailer2,
  trailer3,
  trailer4,
}

const TrailerSection = () => {
  const { lang } = useLangContext()

  return (
    <section>
      <Headline
        title={content.title[lang]}
        back={content.back[lang]}
        desc={content.desc[lang]}
      />
      <div css={gContainer}>
        {trailers.map(
          ({ title, subtitle, desc, icon, button, bullets }, id) => (
            <Trailer
              key={id}
              title={title[lang]}
              subtitle={subtitle[lang]}
              desc={desc[lang]}
              bullets={bullets[lang]}
              button={{ text: button.text[lang] }}
              icon={trailerIcons[icon]}
            />
          )
        )}
      </div>
    </section>
  )
}

export default TrailerSection
